import { FC, useState } from 'react';
import { Input, InputProps } from './Input';
import EyeIcon from '../icon/EyeIcon';
import ClosedEyeIcon from '../icon/ClosedEyeIcon';

export const PasswordInput: FC<InputProps> = (props) => {
  const { value, disabled, label } = props;
  const [inputType, setInputType] = useState('password');

  const passwordToggle = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <div className="relative w-full">
      <Input {...props} type={inputType} autocomplete="new-password" data-testid="password-field" />
      <span
        className={`${!(value && value.length > 0) || disabled ? 'text-color-3' : 'text-black'} absolute right-0 top-1/2 transform ${
          label ? '-translate-y-1/2' : ''
        } mr-2 cursor-pointer`}
        onClick={() => passwordToggle()}
      >
        {inputType === 'password' ? <EyeIcon className="h-6 w-6 stroke-current" /> : <ClosedEyeIcon className="h-6 w-6 fill-current" />}
      </span>
    </div>
  );
};
